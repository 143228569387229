<template>
  <b-container fluid>
    <b-row>
      <b-col lg="10" sm="10" offset="1">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
              <b-form  @submit.prevent="handleSubmit(addTmp)" @reset.prevent="reset">
                <b-overlay :show="unitLoad">
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                    <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="organization"
                        slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                        {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          v-model="serviceOffice.org_id"
                          :options="organizationList"
                          id="org_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Service Name" vid="service_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="service_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('service_name.service_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="serviceOffice.service_id"
                            :options="serviceList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col lg="6" sm="6" md="6">
                      <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                        <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="office_type_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{ $t('component_settings.office_types') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            plain
                            v-model="detailsFormData.office_type_id"
                            :options="officeTypeList"
                            id="office_type_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col lg="2" sm="2" md="2">
                      <b-button type="button" @click="addTmp()" variant="primary" class="mr-2">{{ $t('globalTrans.add') }}</b-button>
                    </b-col>
                  </b-row>
                </b-overlay>
              </b-form>
            </ValidationObserver>
            <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
              <b-form @submit.prevent="handleSubmit(storeUpdate)" @reset.prevent="reset" enctype="multipart/form-data">
                <br>
                <b-row>
                  <b-table-simple  bordered>
                    <thead>
                      <tr class="">
                        <th scope="col">{{ $t('globalTrans.sl_no') }}</th>
                        <th scope="col">{{ $t('component_settings.office_types') }}</th>
                        <th scope="col" >{{ $t('globalTrans.action') }}</th>
                      </tr>
                    </thead>
                    <b-tbody>
                      <slot v-for="(item, index) in serviceOffice.details">
                        <b-tr>
                          <td>
                            {{ $n(index + 1) }}
                          </td>
                          <td>
                            {{ getofficeTypeName(item.office_type_id) }}
                          </td>
                          <td>
                            <b-button type="button" @click="remove(index)" class="btn btn- iq-bg-danger btn-sm text-center ml-1"><i class="ri-delete-bin-line m-0"></i></b-button>
                          </td>
                        </b-tr>
                      </slot>
                    </b-tbody>
                  </b-table-simple>
                </b-row>
                <div class="row">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                    &nbsp;
                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { licenseRegistrationServiceBaseUrl } from '../../../../../config/api_config'
import { serviceofficeTypeStore, serviceofficeTypeUpdate } from '../../api/routes'
import commonAuth from '../CommonAuth'

export default {
  mixins: [commonAuth],
  props: ['item', 'id', 'orgId'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      detailsFormData: {
        office_type_id: 0
      },
      serviceOffice: {
        org_id: 0,
        service_id: 0,
        details: []
      },
      serviceList: [],
      officeTypeList: [],
      unitLoad: false
    }
  },
  created () {
    this.serviceOffice.org_id = this.userCheck()
    if (this.id) {
      this.serviceOffice.org_id = this.orgId
      const tmp = this.getServiceOfficeData(this.item)
      this.serviceOffice.details = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
    'serviceOffice.org_id': function (newVal) {
      this.serviceList = this.getServiceList(newVal)
      this.officeTypeList = this.getOfficeTypeList(newVal)
    }
  },
  methods: {
    getServiceList (orgId) {
      return this.$store.state.licenseRegistration.commonObj.serviceNamesList.filter(item => item.status === 1 && item.org_id === orgId)
    },
    getOfficeTypeList (orgId) {
      return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0 && item.org_id === orgId)
    },
    addTmp () {
      const officeTypeId = this.detailsFormData.office_type_id
      const checkOffice = this.serviceOffice.details.find(item => item.office_type_id === officeTypeId)
      if (typeof checkOffice === 'undefined') {
        this.serviceOffice.details.push(JSON.parse(JSON.stringify(this.detailsFormData)))
        this.detailsFormData.office_type_id = 0
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      }
    },
    getServiceOfficeData (data) {
      const detailData = []
      this.serviceOffice.service_id = this.id
      Object.entries(data).forEach(([key, value]) => {
        Object.entries(value).forEach(([key, value]) => {
          const officeItem = { office_type_id: value.office_type_id }
          detailData.push(officeItem)
        })
      })
      return detailData
    },
    async storeUpdate () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(licenseRegistrationServiceBaseUrl, `${serviceofficeTypeUpdate}/${this.id}`, this.serviceOffice)
      } else {
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, serviceofficeTypeStore, this.serviceOffice)
      }
      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.loading = false

      if (result.exist) {
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
        return
      }

      if (result.success) {
        this.$store.dispatch('licenseRegistration/mutateLicenseRegistrationProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    remove (key) {
      this.serviceOffice.details.splice(key, 1)
    },
    getofficeTypeName (officeType) {
      const tmpOffice = this.officeTypeList.find(office => office.value === officeType)
      return this.$i18n.locale === 'en' ? tmpOffice !== undefined ? tmpOffice.text_en : '' : tmpOffice !== undefined ? tmpOffice.text_bn : ''
    }
  }
}
</script>
<style scoped>
 .table td {
   vertical-align: middle;
 }
</style>
